import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

export default function Payoneer() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/payoneer") {
      const footerEl = document.querySelector("footer");
      document.querySelector(".payoneer-page").style.height = `${
        window.innerHeight - footerEl.offsetHeight
      }px`;
    }
  }, [location.pathname]);

  return (
    // <div className="text-center font-bold text-3xl text-light fixed top-1/2 -translate-y-1/2 left-0 right-0 ">
    <motion.div
      className="payoneer-page text-center font-bold text-3xl text-light flex flex-col items-center justify-center"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.1 } }}
    >
      <h3>
        This site is owned and operated by{" "}
        <span className="text-grey">
          [{" "}
          <span className="text-red-500">
            {" "}
            Amr Ezzat Ahmed, workappen@gmail.com{" "}
          </span>
          ]
        </span>
      </h3>
      <motion.ul className="flex gap-2 mt-4 flex-col text-left">
        <motion.li>
          First Name: <span className="text-gold">Amr </span>
        </motion.li>
        <motion.li>
          Last Name: <span className="text-gold">Ezzat </span>
        </motion.li>
        <motion.li>
          Company Name: <span className="text-gold">amrezzat.com </span>
        </motion.li>
        <motion.li>
          Phone: <span className="text-gold">+20 1222978275 </span>
        </motion.li>
        <motion.li>
          Billing Email Address:{" "}
          <span className="text-gold">workappen@gmail.com </span>
        </motion.li>
        <motion.li>
          Email Address: <span className="text-gold">amr@amrezzat.com </span>
        </motion.li>
        <motion.li>
          Billing Address:{" "}
          <span className="text-gold">
            33 Nour El Islam St, 31823 Al Mahalah Al Kubra - Egypt
          </span>
        </motion.li>
      </motion.ul>
    </motion.div>
  );
}
